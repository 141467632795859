import { createStore as reduxCreateStore } from 'redux';
import * as types from '../constants/ActionTypes';

const reducer = (state, action) => {
    if (action.type === types.SET_HAMBURGER_MENU_STATE) {
        return Object.assign({}, state, { isOpen: action.isOpen });
    }
    if(action.type === types.SET_MAIN_MENU_LIST) {
        return Object.assign ({}, state, { mainMenuList: action.data})
    }

    return state;
};

const initialState = {
    isOpen: false,
    mainMenuList: {}
};

const createStore = () => reduxCreateStore(reducer, initialState)

export default createStore;