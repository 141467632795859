/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import wrapWithProvider from './wrap-with-provider';
import './node_modules/bootstrap/dist/css/bootstrap.min.css';
import './src/stylesheets/basic.scss';
import { defineCustomElements } from '@commonapp/forge/dist/esm/loader';
import './node_modules/@commonapp/forge/dist/forge/forge.css'
import JSZipUtils from 'jszip-utils';

window.JSZipUtils = JSZipUtils;

defineCustomElements();

export const wrapRootElement = wrapWithProvider;